<template>
    <v-dialog v-model="dialog.contacto_lista" persistent max-width="450" :transition="DialogAnimation" overlay-opacity=".25">
        <v-fade-transition>
            <v-card class="rounded-lg">
                <v-card-title class="pa-0 modal-title">
                    <v-toolbar flat class="rounded-t-lg">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                            <v-list-item class="pl-0 rounded-t-lg">
                                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="headline pb-0 mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Contactos</v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        <span class="text-capitalize">Lista</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!--<v-btn icon @click="CerrarLista()" class="mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                </v-card-title>
                <v-card flat max-height="70vh" style="overflow: auto;">
                    <v-container class="pb-0 py-0">
                        <v-row class="px-0">
                            <v-col cols="12" class="pt-0" v-if="lista && lista.length">
                                <v-list class="rounded-lg">
                                    <v-list-item flat v-for="(contacto, index) in lista" :key="index" class="rounded-lg px-1" >
                                        <v-list-item-content class="py-0 rounded-lg">
                                            <v-list-item >
                                                <v-list-item-avatar class="my-1 mr-2" size="30">
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="30">
                                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-information-outline</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                                    <span >{{contacto.nombre}}</span>
                                                    </v-list-item-title>
                                                <v-list-item-subtitle class="text-capitalize">
                                                    {{contacto.contacto}}
                                                </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0 avatar-otros pt-1 ml-1" v-if="contacto.contacto.toString().length >= 9">
                                                    <v-btn icon color="primary" :href="Llamar(contacto.contacto)">
                                                        <v-icon size="20">mdi-phone</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="CerrarLista()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-fade-transition>
    </v-dialog>
</template>

<script>
import {
    mapState,
    mapMutations,
} from "vuex"   
export default {
    name: 'ContactoLista',
    props: ['lista'],
    data() {
        return {
            turno_id: null,
            dialog_turno: false,
            nulo: {
                lista : [],
                fecha: null,
                menu: false
            }
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario','sistema']),
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'CloseDialog','OpenDialog']),
        //Muestra el detalle del turno con base en su id
        CerrarLista() {
            this.CloseDialog('contacto_lista')
        },
        Llamar(telefono){
            let href = ''
            if(telefono.toString().length == 9){
                if(telefono.toString().charAt(0) == 9) href = `tel:+56${telefono}`
                else href = `tel:${telefono}`
            }
            return href
        }
    }
}
</script>

<style scoped>
.v-list-item--link:before {
    border-radius: 8px !important;
}
</style>